import * as React from 'react';
import styled from 'styled-components';
import Button from '../atoms/Button';
import { ButtonShare } from '../atoms/ButtonShare';
import Popup from 'reactjs-popup';
import ContactPopUp, { toSingleString } from '../organisms/ContactPopUp';
import noPicture from '../../images/no-picture.svg';
import noPictureSmall from '../../images/no-picture-small.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { WpUser } from '../../shared/wp-user-utils';
import Img from "gatsby-image";

export interface SignatureProps {
    isStatic?: boolean;
    user: WpUser;
    author: string;
    link?: string;
    phoneNumber: string;
    email: string;
    SEOTitle?: string;
    SEODescription?: string;
}


const SignatureBox = styled.div<Pick<SignatureProps, "isStatic">>`
    display: flex;
    height: 40px;
    align-items: center;
    position: ${({isStatic}) => isStatic ? "static" : "absolute"};
    left: 133px;
    bottom: 80px;

    .authorImage--small {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 20px;
    }

    @media (min-width: 992px) and (max-width: 1600px) {
        left: 100px;
        bottom: 45px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        left: 60px;
        bottom: 40px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: none;
    }
`;

const AuthorImage = styled.img`
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    margin-right: 20px;
`

const AuthorContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;

`;

const AuthorName = styled.p`
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    font-size: Museo;
    color: ${({ theme }) => theme.blueColor};
    font-weight: 600;
    white-space: nowrap;
`;

// const AuthorPosition = styled(AuthorName)`
//     color: ${({ theme }) => theme.blackColor};
//     margin-top: 4px;
//     font-weight: 300;
//     max-width: 250px;
// `;

const StyledButton = styled(Button)`
    margin-right: 24px;
    width: 160px;
    height: 37px;
    background-color: transparent;
    font-size: 14px;
    border: 2px solid ${({ theme }) => theme.blueColor};
`;


export const SignatureHeader: React.FC<SignatureProps> = ( {isStatic = false, user, author, SEODescription, SEOTitle, link, phoneNumber, email} ) => {
	const {t} = useTranslation();
    
    const photo = user.user?.photo?.localFile.childImageSharp.fluid;

    return ( 

        <SignatureBox isStatic={isStatic}>
           {photo ? <Img className="authorImage--small" fluid={photo} alt={toSingleString(author)} loading="lazy"/> : <AuthorImage src={noPictureSmall} alt="zdjęcie autora" loading="lazy"/>}
            <AuthorContent>
                <AuthorName>{author}</AuthorName>
            </AuthorContent>
            <Popup trigger={<StyledButton type="white">{t('makeContact')}</StyledButton>} modal>
             { close => <ContactPopUp photo={photo} author={author} close={close} phoneNumber={phoneNumber} email={email}/>}
            </Popup>
            {(link && SEOTitle && SEODescription) && <ButtonShare link={link} SEODescription={SEODescription} SEOTitle={SEOTitle}/>}
        </SignatureBox>
     );
}
 
