import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import ArrowBack from '../../images/arrow-back.svg';

type HeaderNavigationProps = {
    isArrowVisible: boolean;
    firstLink: string;
    secondLink?: string;
    firstLinkName: string;
    secondLinkName?: string;
}

const HeaderNavigationContent = styled.div`
    display: flex;
    align-items: center;
    height: 29px;
`;

const StyledImage = styled.img`
    margin-right: 16px;
    width: 23px;
`;

const StyledLink = styled(Link)`
    height: 100%;
    padding-right: 5px;
    line-height: 29px;
    font-family: Museo Sans;
    font-weight: 300;
    font-size: 18px;
    font-family: Museo;
    text-decoration: none;
    color: ${({ theme }) => theme.blueColor};
`;
 
export const HeaderNavigation: React.FC<HeaderNavigationProps> = ( {isArrowVisible, firstLink, secondLink, firstLinkName, secondLinkName} ) => {
    return ( 
        <>
            <HeaderNavigationContent>
                { isArrowVisible && <StyledLink to={`/${firstLink.toLowerCase()}/`}><StyledImage src={ArrowBack} alt="ikona strzałka lewa"/></StyledLink>}
                { firstLink && <StyledLink to={`/${firstLink.toLowerCase()}/`}>{firstLinkName}</StyledLink>}
                { firstLink && secondLink && <StyledLink to={`/${firstLink.toLowerCase()}/${secondLink.toLowerCase()}`}> \ {secondLinkName}</StyledLink>}
            </HeaderNavigationContent>
        </>
     );
}
