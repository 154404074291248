import React from 'react';
import styled from 'styled-components';
import IconEnvelope from '../../images/icon-envelope.svg'
import IconPhone from '../../images/icon-phone.svg'
import ButtonAnchor from '../atoms/ButtonAnchor';
import { PupUpHeader } from '../atoms/PopUpHeader';
import { PopUpContainer } from '../molecules/PopUpContainer';
import { PopUpButtonExit } from '../atoms/PopUpButtonExit';
import { formatPhoneNumber } from '../formatPhoneNumber'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Img from "gatsby-image";
import { FluidObject } from "gatsby-image";
import noPicture from '../../images/no-picture.svg';

const PopUpName = styled.span`
    font-family: Museo;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: ${({ theme }) => theme.primaryColor};
    margin-bottom: 26px;
`

// const PopUpRole = styled.span`
//     color: ${({ theme }) => theme.greyColor};
//     margin-bottom: 4px;
// `

// const PopUpCity = styled.span`
//     margin-bottom: 16px;
// `

const PopUpIcon = styled.img`
    width: 100%;
    height: 100%;
    width: 24px;
    max-height: 24px;

    @media (max-width: 1600px) {
        width: 16px;
        max-height: 16px;
    }
`

const PopUpContactItems = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    div:last-of-type {
        border-bottom: none;
    }
`

const PopUpContactItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.lightGreyColor};
    
    @media (max-width: 1600px) {
        padding: 10px 0;
    }
`

const PopUpAnchor = styled.a`
    font-family: Museo Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 150%;
    color: ${({ theme }) => theme.primaryColor};
    text-decoration: none;

    :hover {
        color: ${({ theme }) => theme.blueColor};
    }
`

const PopUpButtons = styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;

        a:first-of-type {
            margin-right: 12px;
            width: 50%;
        }

        a:last-of-type {
            margin-left: 12px;
            width: 50%;
        }  

        @media (min-width: 768px) {
            width: 100%;
            justify-content: center;

            a:last-of-type {
                margin-left: 0;
            }  
        }

        @media (min-width: 768px) and (max-width: 1600px) {
            margin-top: 20px;
        }
`;

const StyledButtonAnchor = styled(ButtonAnchor)`
    min-width: 160px;
    
    @media (max-width: 767.98px) {
        min-width: 0;
        font-size: 17px;
    }  
`;

const AuthorImage = styled.img`
    margin-bottom: 25px;
    height: 188px;
    width: 188px;
    border-radius: 50%;
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        height: 150px;
        width: 150px;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        height: 150px;
        width: 150px;
    }

    @media (max-width: 767.98px) {
        width: 130px;
        height: 130px;
    }  
`

type PopUpProps = {
    close: any;
    photo?: FluidObject;
    author: string | string[];
    phoneNumber: string | string[];
    email: string | string[];
}

export function toSingleString(s: string | string[]) {
    return Array.isArray(s) ? s[0] : s;
}

const ContactPopUp: React.FC<PopUpProps> = ({close, photo, author, phoneNumber, email}) => {
    const {t} = useTranslation();
    
    const ContactItems = [
        {   
            name: toSingleString(email),
            href: `mailto:${toSingleString(email)}`,
            icon: <PopUpIcon src={IconEnvelope} alt="envelope icon" />,
        },
        {   
            name: formatPhoneNumber(toSingleString(phoneNumber)),
            href: `tel:${formatPhoneNumber(toSingleString(phoneNumber))}`,
            icon: <PopUpIcon src={IconPhone} alt="smartphone icon" />,
        },
    ]

    return (
        <PopUpContainer method={close}>
                <PupUpHeader method={close} title={t('makeContact')}/>
                {photo ? <Img className="contactPopup__image" fluid={photo} alt={toSingleString(author)}/> : <AuthorImage src={noPicture} alt="zdjęcie autora" />}

                <PopUpName>{toSingleString(author)}</PopUpName>
                <PopUpContactItems>
                    {
                        ContactItems.map((item, index) => {
                            if(item.name) {
                                return(
                                    <PopUpContactItem key={index}>
                                        <PopUpAnchor href={item.href}>{item.name}</PopUpAnchor>
                                        {item.icon}
                                    </PopUpContactItem>)
                            } else {
                                return null;
                            }
                        })
                    }
                </PopUpContactItems>
                <PopUpButtons>
                    {phoneNumber && <StyledButtonAnchor type="white" href={`tel:${toSingleString(phoneNumber)}`}>{t('call')}</StyledButtonAnchor>}
                    <StyledButtonAnchor type="blue" href={`mailto:${toSingleString(email)}`}>{t('sendEmail')}</StyledButtonAnchor>
                </PopUpButtons>
                <PopUpButtonExit method={close} />
        </PopUpContainer>
    )
}

export default ContactPopUp;
