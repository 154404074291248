import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ShareIcon from '../../images/share-icon.svg';
import FacebookIcon from '../../images/icon-facebook.svg';
import TwitterIcon from '../../images/icon-twitter.svg';
import LinkedIcon from '../../images/icon-linked2.svg';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { useTranslation } from 'gatsby-plugin-react-i18next';

type ShareButtonsPopUpPops = {
    isPopUpOpen: boolean;
}

type ButtonShareProps = {
    link: string;
    SEOTitle: string;
    SEODescription: string;
}

const SocialButtonsWrapper = styled.div<ShareButtonsPopUpPops>`
    display: ${({isPopUpOpen}) => isPopUpOpen ? "flex" : "none"};
    align-items: center;
    padding: 0 16px;

    button {
        margin: 0 8px;
    }
`;

const ShareWrapper = styled.div`
    display: flex;
    align-items: center;
    border-left: 1px solid #9C9C9C;
    height: 100%;
    padding-left: 18px;

    @media (max-width: 767.98px) {
        border-left: none;
    }
`;

const StyledButtonShare = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;

    :hover {
        filter: brightness(1.4);
    }
`;

const ShareText = styled.span`
    margin-left: 8px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    font-family: Museo Sans;
    color: ${({ theme }) => theme.primaryColor};
    cursor: pointer;
`;

const SocialIcon = styled.img`
    width: 34px;
    height: 34px;
    border-radius: 4px;

    :hover {
        filter: brightness(1.4);
    }
`;

 
export const ButtonShare: React.FC<ButtonShareProps> = ({link, SEOTitle, SEODescription}) => {
    const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false)
	const {t} = useTranslation();
    
    return ( 
            <ShareWrapper>
                <StyledButtonShare onClick={() => setIsPopUpOpen(!isPopUpOpen)}>
                    <img src={ShareIcon} alt="ikona udostępnij"/>
                    <ShareText>{t('share')}</ShareText>
                </StyledButtonShare>
                <SocialButtonsWrapper isPopUpOpen={isPopUpOpen}>
                    <FacebookShareButton quote={SEOTitle} url={link}>
                        <SocialIcon src={FacebookIcon} alt="facebook"/>
                    </FacebookShareButton>
                    <TwitterShareButton title={SEOTitle} url={link}>
                        <SocialIcon src={TwitterIcon} alt="twitter"/> 
                    </TwitterShareButton>
                </SocialButtonsWrapper>
            </ShareWrapper>
     );
}
