import * as React from 'react';
import styled from 'styled-components';
import { ButtonShare } from '../atoms/ButtonShare';
import MessageTriangle from '../../images/message-triangle.png';
import Popup from 'reactjs-popup';
import ContactPopUp, { toSingleString } from '../organisms/ContactPopUp';
import Button from '../atoms/Button';
import noPicture from '../../images/no-picture.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { WpUser } from '../../shared/wp-user-utils';
import Img from "gatsby-image";

export interface SignatureProps {
    user: WpUser;
    author: string;
    link?: string;
    phoneNumber: string;
    email: string;
    SEODescription?: string;
    SEOTitle?: string;
}

const SignatureBox = styled.div`
   display: none;

   .authorImage--mobile {
        border-radius: 50%;
        margin: 40px 0 32px;
        width: 90px;
        height: 90px;
   }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: column;
        margin: 40px auto;
        width: 327px;
        height: 300px;
        border-radius: 16px;
        background-color: #F5F5F5;
        border: 1px solid #C4C4C4;
    }
`;

const AuthorImage = styled.img`
    border-radius: 50%;
    margin: 40px 0 32px;
    width: 90px;
    height: 90px;
`

const Triangle = styled.div`
    position: absolute;
    bottom: -34px;
    left: 30px;
    height: 34px;
    width: 43px;
    z-index:1;
    background-image: url(${MessageTriangle});
    background-size: cover;
`;

const AuthorContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 25px;
    text-align: center;
`;

const AuthorName = styled.p`
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    font-size: Museo;
    color: ${({ theme }) => theme.blueColor};
    font-weight: 600;
`;

// const AuthorPosition = styled(AuthorName)`
//     color: ${({ theme }) => theme.blackColor};
//     margin-top: 4px;
//     font-weight: 400;
// `;

const StyledButton = styled(Button)`
    height: 37px;
    background-color: transparent;
    width: 180px;
    padding: 17px 30px;
    margin: 0 auto;
    font-size: 14px;
    border: 2px solid ${({ theme }) => theme.blueColor};
`;

export const SignatureMobile: React.FC<SignatureProps> = ( {user, author, link, phoneNumber, email, SEODescription, SEOTitle} ) => {
    const {t} = useTranslation();
    const photo = user.user?.photo?.localFile.childImageSharp.fluid;

    return ( 
        <SignatureBox>
            <Triangle/>
            {photo ? <Img className="authorImage--mobile" fluid={photo} alt={toSingleString(author)} loading="lazy"/> : <AuthorImage src={noPicture} alt="zdjęcie autora" loading="lazy"/>}

            <AuthorContent>
                <AuthorName>{author}</AuthorName>
            </AuthorContent>

            <Popup trigger={<StyledButton type="white">{t('makeContact')}</StyledButton>} modal>
             { close => <ContactPopUp photo={photo} author={author} close={close} phoneNumber={phoneNumber} email={email}/>}
            </Popup>

            {(link && SEOTitle && SEODescription) && <ButtonShare link={link} SEODescription={SEODescription} SEOTitle={SEOTitle}/>}
        </SignatureBox>
     );
}
 
